import { tw } from '@/utils/tw';
import AccordionThemeBase from 'base/components/Accordion/theme';

const AccordionTheme = tw.theme({
  extend: AccordionThemeBase,
  variants: {
    type: {
      rich: {
        base: ['bg-linen-200'],
        headline: ['md:text-content-md', 'text-content-sm'],
      },
    },
  },
});

export default Object.assign(AccordionTheme, { Icon: AccordionThemeBase.Icon });
